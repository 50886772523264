<template>
  <modal
    name="acceptRequestCompanyPopup"
    transition="pop-out"
    @before-open="beforeOpen"
    @closed="$v.$reset()"
  >
    <div class="formPopup" id="signUpPopup">
      <div class="formPopup__inner">
        <div class="formPopup__left">
          <div class="formPopup__decor"></div>
        </div>
        <div class="formPopup__right">
          <div style="justify-content:center;" class="formPopup__header">
            <div class="formPopup__title-wr">
              <div style="font-size:1.7em;" class="formPopup__title">SELECT A COMPANY</div>
            </div>
          </div>
          <div class="formPopup__tabContent">
            <div class="formPopup__tabContent-item js-tabSignContent active" data-id="flyer">
              <form
                class="form form--signUpForm js-ajaxForm"
                :class="{'loading' : loading}"
                data-href="#"
                data-action="signUpFlyer"
                id="signUpFlyerForm"
                @submit.prevent="submitHandlerCompany">
                <Spinner/>
                <div class="form__labelGroup">
                  <label class="form__label form__label--text" style="width: 100%">
                    <div class="form__caption">COMPANY NAME</div>
                    <div class="form__field-wrapper">
                      <input
                        class="form__field"
                        type="text"
                        v-bind:value="companyName"
                        placeholder="Company XYZ"
                        @input="getCompany($event.target.value)"
                        @blur="showHideList(false)"
                        :disabled="loading"
                      />
                      <div
                        class="typeahead-menu typeahead-empty"
                        :class="{'typeahead-open' : showCompaniesList === true}"
                      >
                        <div class="typeahead-dataset typeahead-dataset-departureLocations">
                          <div
                            class="typeahead-suggestion typeahead-selectable"
                            data-type="select-from"
                            v-for="(item, index) of companiesList" :key="index"
                            @click="selectCompanie(item)"
                          >{{item.name}}</div>
                        </div>
                      </div>
<!--                      <input-->
<!--                        class="form__field js-typeahead"-->
<!--                        type="text"-->
<!--                        v-bind:value="from"-->
<!--                        data-type="from"-->
<!--                        @input="getLocationsFrom($event.target.value)"-->
<!--                        @focus="showTypeheadMenu('from')"-->
<!--                        @blur="hideTypeheadMenu"-->
<!--                        :disabled="loading"-->
<!--                        placeholder="Take off"-->
<!--                      />-->
                    </div>
                    <span
                      v-if="$v.companyName.$dirty && !$v.companyName.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                </div>
                <div style="margin-bottom:0;" class="form__label form__label--button">
                  <button
                    class="form__button form__button"
                    :disabled="loading"
                  >
                    <span class="text">Complete</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('acceptRequestCompanyPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
import Spinner from '@/components/common/Spinner/Spinner'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import { Tabs } from '@/js/plugins/tabs'
import { required } from 'vuelidate/lib/validators'
import {adminRequestsAPI} from '@/api/dashboard/adminRequests';

export default {
  name: 'AcceptRequestCompanyPopup',
  data: () => ({
    tabs: new Tabs('.js-tabSignItem', '.js-tabSignContent'),
    companyName: '',
    companyid: '',
    showCompaniesList: false,
    loading: false,
    companiesList: [],
  }),
  validations: {
    companyName: { required },
  },
  methods: {
    beforeOpen () {
      setTimeout(() => {
        this.tabs.addActiveForMouted()
      }, 0)
    },
    async getCompany (value) {
      this.companyName = value
      if (value.trim().length > 0) {
        this.showCompaniesList = true
        const {data} = await adminRequestsAPI.getCompanies(value)
        this.companiesList = data
      } else {
        this.companiesList = [];
      }
    },
    showHideList (value) {
      setTimeout(() => { this.showCompaniesList = value; }, 500)
    },
    selectCompanie (item) {
      this.companyName = item.name
      this.companyid = item.id
      this.showCompaniesList = false
      document.querySelector('.typeahead-menu').classList.remove('typeahead-open');
    },
    async submitHandlerCompany () {
      if (this.$v.companyName.$invalid) {
        this.$v.$touch()
        return false
      }

      this.loading = true

      try {
        await adminRequestsAPI.acceptRequestCompany({...this.$store.state.adminRequests.currentCompany, new: false, existingCompanyId: this.companyid})
        // console.log(this.$store.state.adminRequests.currentCompany)
        this.$modal.show('InfoPopup', {
          title: 'Request Accepted',
          text: 'The aircraft provider is accepted successfully',
        })
        this.$emit('reloadProviderRequests')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.$modal.hide('acceptRequestCompanyPopup')
      }
    },
  },
  components: {
    Spinner,
    SvgIcon,
  },
}
</script>
