<template>
  <div class="signUpDashboard">
    <div class="signUpDashboard__header">
      <div class="signUpDashboard__title">Aircraft provider sign-ups and confirms</div>
      <router-link class="signUpDashboard__button" to="">
        <span
        @click="exportToExcel()"
        class="text">Export</span>
      </router-link>
    </div>
    <div class="signUpDashboard__table">
      <Pagination
            :pageCount="providerRequestsPageCount"
            :rowCount="providerRequestsCount"
            @pageClick="onPageClick"
            ref="pagination"
          />
      <div class="signUpDashboard__table-header">
        <div class="signUpDashboard__table-cell">№</div>
        <div class="signUpDashboard__table-cell">Name</div>
        <div class="signUpDashboard__table-cell">Email</div>
        <div class="signUpDashboard__table-cell">Phone</div>
        <div class="signUpDashboard__table-cell">Company</div>
        <div class="signUpDashboard__table-cell ">Approved As</div>
        <div class="signUpDashboard__table-cell signUpDashboard__table-cell--date">Date/Time</div>
      </div>
      <div class="signUpDashboard__table-body js-customScroll">
        <vue-scroll :ops="ops">
          <div
            v-for="item of requestsList"
            :key="item.id"
            class="signUpDashboard__table-row"
          >
            <div
              class="signUpDashboard__table-row-inner js-acc-hover-item"
              @mouseenter="controlShow"
              @mouseleave="controlHide"
            >
              <div class="signUpDashboard__table-row-header">
                <div class="signUpDashboard__table-cell">
                  <div class="signUpDashboard__table-index">{{item.number}}</div>
                </div>
                <div class="signUpDashboard__table-cell">
                  <router-link class="signUpDashboard__table-name" to="">{{item.firstName + ' ' + item.lastName}}</router-link>
                </div>
                <div class="signUpDashboard__table-cell">{{item.email}}</div>
                <div class="signUpDashboard__table-cell">
                  <div class="signUpDashboard__table-phone">{{item.mobile}}</div>
                </div>
                <div class="signUpDashboard__table-cell">{{item.companyName}}</div>
                <div class="signUpDashboard__table-cell">
                  <div class="signUpDashboard__table-status">
                    <span class="signUpDashboard__table-status-value">{{item.company ? item.company.name : "N/A"}}</span>
                  </div>
                </div>
                <div class="signUpDashboard__table-cell signUpDashboard__table-cell--date">{{moment(item.createdOn).format('M/D/YYYY')}}</div>
              </div>
              <div class="signUpDashboard__table-row-control js-acc-hover-content">
                <div class="signUpDashboard__table-row-control-inner">
                  <p class="signUpDashboard__table-link" @click="() => acceptRequestCreateCompany(item)">
                    <span class="icon" />
                    <span class="text">Accept request (Create Company)</span>
                  </p>
                  <p class="signUpDashboard__table-link" @click="() => showModalCompany(item)">
                    <span class="icon" />
                    <span class="text">Accept request (Link Company)</span>
                  </p>
                  <p class="signUpDashboard__table-link">
                    <span class="icon" />
                    <span class="text">Decline request</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <InfoPopup />
    <AcceptRequestCompanyPopup @reloadProviderRequests="$refs.pagination.showPage()"/>
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import './SignUpDashboard.styl'
  import moment from 'moment'
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import {adminRequestsAPI} from '@/api/dashboard/adminRequests';
  import AcceptRequestCompanyPopup from '@/components/common/modals/AcceptRequestCompanyPopup';
  import Pagination from '@/components/common/Pagination/Pagination'

  export default {
    name: 'SignUpDashboard',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      requestsList () {
        return this.$store.state.adminRequests.providerRequests
      },
      providerRequestsPageCount () {
        return this.$store.state.adminRequests.providerRequestsPageCount
      },
      providerRequestsCount () {
        return this.$store.state.adminRequests.providerRequestsCount
      },
    },
    methods: {
      moment,
      async exportToExcel () {
        // this.$store.commit('exportToExcel', 'apRequests');
        // below in store logic for testing.
        // var fileUrl = document.createElement('downloadlink');
        // fileUrl.href = this.$store.state.adminRequests.exportBlob;
        // fileUrl.setAttribute('download', 'apRequests' + '_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv')
        // console.log(fileUrl.getAttribute('download'))
        // document.body.appendChild(fileUrl);
        // fileUrl.click();
        // document.body.removeChild(fileUrl);

        try {
          const { data } = await reportsAPI.apRequests();

          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'apRequests_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e);
        }
      },
      controlShow (event) {
        const item = event.currentTarget.closest('.signUpDashboard__table-row').querySelector('.js-acc-hover-content')
        this.$root.animations.slideDown(item)
        item.classList.add('show')
      },
      controlHide (event) {
        const item = event.currentTarget.closest('.signUpDashboard__table-row').querySelector('.js-acc-hover-content')
        item.classList.remove('show')
        this.$root.animations.slideUp(item)
      },
      async acceptRequestCreateCompany (company) {
        try {
          await adminRequestsAPI.acceptRequestCompany({...company, new: true, existingCompanyId: null})
          this.$modal.show('InfoPopup', {
            title: 'Request Accepted',
            text: 'The aircraft provider is accepted successfully',
          })
          this.$refs.pagination.showPage();
        } catch (e) {
          console.log(e)
        }
      },
      showModalCompany (company) {
        this.$modal.show('acceptRequestCompanyPopup')
        this.$store.commit('setCurrentCompany', company);
      },
      toggleApprovedRequest (event) {
        const item = event.currentTarget.closest('.signUpDashboard__table-row-inner').querySelector('.js-acc-content')
        event.currentTarget.closest('.signUpDashboard__table-row').classList.toggle('mutation')
        document.querySelectorAll('.js-acc-content').forEach((content) => {
          if (item !== content) {
            content.querySelector('.signUpDashboard__table-row-content-inner').style.opacity = 0
            content.closest('.signUpDashboard__table-row').classList.remove('mutation')
            this.$root.animations.slideUp(content)
          }
        })

        this.$root.animations.slideToggle(item)
        const innerContent = item.querySelector('.signUpDashboard__table-row-content-inner')

        if (window.getComputedStyle(innerContent).opacity === '1') {
          innerContent.style.opacity = 0
        } else {
          item.addEventListener('transitionend', () => {
            if (window.getComputedStyle(innerContent).opacity === '0') {
              innerContent.style.opacity = 1
            }
          }, { once: true })
        }
      },
      async onPageClick (data) {
        await this.$store.dispatch('getProviderRequests', data);
      },
    },
    components: {
      InfoPopup,
      AcceptRequestCompanyPopup,
      Pagination,
    },
  }
</script>
